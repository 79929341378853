
import { defineComponent, ref } from 'vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'

export default defineComponent({
  components: {
    TmScrollbar,
  },
  setup() {
    const textTypeOptions = [
      'Normal text',
      'Title',
      'Heading 1',
      'Heading 2',
      'Help text',
    ]

    const textType = ref(textTypeOptions[0])

    return {
      textType,
      textTypeOptions,
    }
  },
})
